.btn {
    border-radius: 8px;
    padding: 8px 16px;
    border: none;
    outline: none;
    text-decoration: none;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.btn-l {
    padding: 12px 16px;
}

.btn-primary *, .btn-danger * {
    color: var(--white);
}

.btn-link * {
    color: var(--blueA700);
}

.btn-secondary * {
    color: var(--grey700);
}

.btn-primary {
    color: var(--white);
    background: var(--orange);
}

.btn-danger {
    color: var(--white);
    background: var(--redA700);
}

.btn-link {
    padding: 0;
    color: var(--white);
    background: transparent;
}

.btn-secondary {
    color: var(--grey700);
    background: var(--grey100);
    border: 1px solid var(--grey200);
}

.btn-primary:hover, .btn-danger:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

.btn-link:hover span {
    text-decoration: underline;
}

.btn-secondary:hover {
    box-shadow: 0 2px 8px -4px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.12);
}

.btn-primary:active, .btn-danger:active {
    padding: 8px 16px !important;
    background: var(--orange100);
    box-shadow: none !important;
    border: none !important;
}

.btn-danger:active {
    background: var(--redA700)
}

.btn-secondary:active {
    padding: 8px 16px !important;
    background: #F0F0F0;
    box-shadow: none !important;
    border: none !important;
}

.btn-primary:focus, .btn-secondary:focus, .btn-danger:focus {
    padding: 7px 15px;
    border: 1px solid #57AEFF;
    box-shadow: 0 0 0 2px rgba(87, 174, 255, 0.4);
}

.btn-link:focus span {
    border: 1px solid #57AEFF;
    box-sizing: border-box;
    border-radius: 8px;

    padding: 7px 0;
}

.btn-l:focus {
    padding: 11px 15px !important;
}

.btn-l:active {
    padding: 12px 16px !important;
}

.btn-disabled, .btn-disabled:hover, .btn-disabled:focus, .btn-disabled:active {
    padding: 11px 15px;
    cursor: not-allowed;
    background: var(--grey100);
    border: 1px solid var(--grey200);
    box-shadow: none;
}

.btn-disabled {
    background: var(--white);
    border: none;
}

.btn-disabled, .btn-link {
    background: transparent;
}

.btn-disabled, .btn-link:hover {
    background: transparent;
    border: none;
}

.btn-disabled * {
    color: var(--grey400)
}