.text {
    font-style: normal;
    letter-spacing: -0.02em;
    color: var(--grey900);
    text-decoration: none;
}

.text-h1 {
    font-size: 64px;
    line-height: 96px;
}

.text-h2 {
    font-size: 40px;
    line-height: 60px;
}

.text-h3, .text-subtitle {
    font-size: 24px;
    line-height: 36px;
}

.text-subtitle {
    font-weight: 500;
}

.text-body, .text-bold {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.text-small {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.text-pretitle {
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */

    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.text-h1, .text-h2, .text-h3, .text-pretitle, .text-bold {
    font-weight: bold;
}

.text-h1_jp, .text-h2_jp, .text-h3_jp, .text-subtitle_jp,
.text-small, .text-body, .text-bold, .text-subtitle {
    letter-spacing: unset;
}

.text-body_jp, .text-bold_jp, .text-small_jp, .text-pretitle_jp {
    letter-spacing: 0.05em;
}

.pointer {
    cursor: pointer;
}
