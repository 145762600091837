.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;

    height: 100%;

    background-image: url('/images/service.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.form {
    background: var(--white);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    padding: 80px;
    box-sizing: border-box;

    max-width: calc(355px + 160px);
}

.form > * {
    margin-bottom: 32px;
}

.form > button {
    margin-bottom: unset;
}

.form img {
    width: 355px;
    height: 113px;
}

.align{
    margin-top: 32px;
    text-align: center;
}