.root {
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    height: 64px;
    background-color: var(--white);

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    padding: 0 32px;

    z-index: var(--z-index-nav-bar);
}

.logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
}

.profileImg {
    margin-right: 0 !important;
    margin-left: 16px;
    cursor: pointer;
}

.profileMenuVisible {
    visibility: visible !important;
}

.profileMenuAnimateOpen {
    animation: zoom-in-out 100ms linear;
}

.profileMenu {
    visibility: hidden;

    position: absolute;
    top: 64px;
    right: 32px;

    z-index: var(--z-index-profile-menu);
}

@keyframes zoom-in-out {
    from {
        transform: scale(1.05);
    }

    to {
        transform: scale(1);
    }
}

.overlay {
    position: fixed;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: var(--z-index-overlay);
}

.nav-items-and-buttons, .navs, .root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navs {
    padding-top: 8px;
}

.buttons {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
}

.buttons > *:first-child {
    margin-right: 16px;
}

.locale {
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.localeMenu {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    display: grid;
    grid-auto-flow: row;

    position: absolute;
    top: -12px;
    right: 0;

    overflow-clip: clip;
}

.localeMenu div:hover {
    background-color: var(--grey200);
}

.localeMenu div {
    padding: 8px 16px
}