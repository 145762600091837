.textFieldRoot {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.spacingTop {
    margin-top: 8px;
}

.spacingBottom {
    margin-bottom: 8px;
}

.textFieldWithActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    padding: 0 16px;

    background: var(--white);
    border: 1px solid var(--grey300);
    box-sizing: border-box;
    border-radius: 8px;
}

.rightIcon {
    display: flex;
    align-items: center;

    cursor: pointer;

    height: 100%;
}

.readOnlyTextField {
    cursor: pointer;
}

.textField {
    padding: 11px 0;
    border: none;
    outline: none;
    flex: 1;

    width: 100%;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: var(--grey900);
    letter-spacing: 0.05em;
}

.textFieldFilled {
    border: 1px solid #57AEFF;
}

.textField:focus, .textField:active {
    outline: none;
}

.textFieldWithActions:focus-within {
    border: 1px solid #57AEFF;
    box-shadow: 0 0 0 2px rgba(87, 174, 255, 0.4);
}

.hasError {
    border: 1px solid var(--redA700);
    box-shadow: 0 0 0 2px var(--red100);
}