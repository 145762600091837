.nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    text-decoration: none;
}

.nav-item:hover .selected-indicator {
    visibility: visible;
}

.selected-indicator-hidden {
    visibility: hidden;
}

.selected-indicator {
    width: calc(100% - 24px);
    height: 2px;
    background: #3C7F86;
}

.nav-text {
    margin: 16px 16px 24px 16px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;

    letter-spacing: 0.05em;
    color: #3C7F86;
}