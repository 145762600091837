.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    width: 305px;
    background: var(--white);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    box-sizing: border-box;
}

.profile {
    margin-bottom: 8px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 16px;

    border-bottom: 1px solid var(--grey300);
}

.profile > div {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: flex-start;
}

.profile span:first-child {
    margin-bottom: 8px;
}

.profileNameAndEmail > span {
    text-overflow: ellipsis;
    max-width: 209px;
    overflow: hidden;
}

.profileImg {
    min-width: 48px;
    width: 48px;
    height: 48px;

    object-fit: fill;
    overflow: hidden;

    border-radius: 100%;

    margin-right: 16px;
}

.link {
    text-decoration: none;

    padding: 8px 16px;

    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
}

.link:hover {
    background: var(--grey200);
}

.link:hover span {
    color: var(--grey900) !important;
}

.link span {
    flex-grow: 1;
    margin-left: 8px;
}

.link:last-child {
    margin-bottom: 8px;
}